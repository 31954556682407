import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import DataManagement from "./pages/DataManagement";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "privacy", 
    element: <Privacy />
  },
  {
    path: "data-management", 
    element: <DataManagement />
  }
]);

export default router;