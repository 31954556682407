import React from 'react';

// styles
const subHeaderWrapper = {
  padding: 30,
};

const content = {
  maxWidth: 1200,
  margin: 'auto',
};

const h1 = {
  fontSize: 30,
  fontWeight: 600,
};

export default function DataManagement() {
  return (
    <>
      <div style={subHeaderWrapper} className='subHeaderWrapper'>
        <div style={content}>
          <p style={h1}>Managing your data</p>
          <p>With Football Speedster, you are always in control of your data. If you would like us to delete all your data, please send a request to hello@footballspeedster.com and we will delete your data immediately.</p>
        </div>
      </div>
    </>
  );
}
