import React from 'react';

// styles
const subHeaderWrapper = {
  padding: 30,
};

const content = {
  maxWidth: 1200,
  margin: 'auto',
};

const h1 = {
  fontSize: 30,
  fontWeight: 600,
};

const bold = {
  fontSize: 20,
  fontWeight: 600,
};

export default function Privacy() {
  return (
    <>
      <div style={subHeaderWrapper} className='subHeaderWrapper'>
        <div style={content}>
          <p style={h1}>Football Speedster Privacy Policy</p>
          <p style={bold}>1. We respect your privacy</p>
          <p>(a) Football Speedster respects your right to privacy and is committed to safeguarding the privacy of ourcustomers and software application users. We adhere to the National Privacy Principlesestablished by the Privacy Act 1988 (Cth). This policy sets out how we collect and treatyour personal information.</p>
          <p>(b) Personal information means information we hold which is identifiable as being about you. </p>

          <p style={bold}>2. Collection of personal information</p>
          <p>(a) Football Speedster will, from time to time, receive and store personal information you enter onto oursoftware application Football Speedster, provide to us directly or give to us in other forms.</p>
          <p>(b) You may provide basic information such as your name, phone number, address and emailaddress to enable us to send information, provide updates and process your product orservice order. We may collect additional information at other times, including but not limited to, when you provide feedback, when you provide information about your personal orbusiness affairs, change your content or email preference, respond to surveys and/or promotions, provide financial or credit card information, or communicate with our customer support.</p>
          <p>(c) Additionally, we may also collect any other information you provide while interacting with us.</p>
          
          <p style={bold}>How we collect your personal information</p>
          <p>(a) Football Speedster collects personal information from you in a variety of ways, including when you interactwith us electronically or in person, when you access our software application and when weprovide our services to you. We may receive personal information from third parties. If we do, wewill protect it as set out in this Privacy Policy.</p>
          
          <p style={bold}>Use of your personal information</p>
          <p>(a) Football Speedster may use personal information collected from you to provide you with information, updates and our services. We may also make you aware of new and additional products,services and opportunities available to you. We may use your personal information to improve our products and services and better understand your needs.</p>
          <p>(b) The Application may make third party social media features available to its users. We cannot ensure the security of any information you choose to make public in a social media feature. Also, we cannot ensure that parties who have access to such publicly available information will respect your privacy.</p>
          <p>(c) Football Speedster may contact you by a variety of measures including, but not limited to telephone,email, sms or mail.</p>

          <p style={bold}>Disclosure of your personal information</p>
          <p>(a) We may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers or subcontractors insofar as reasonably necessary for the purposes set out in this Policy. Personal information is only supplied to a third party when it is required for the delivery of our services.</p>
          <p>(b) We may from time to time need to disclose personal information to comply with a legal requirement, such as a law, regulation, court order, subpoena, warrant, in the course of a legal proceeding or in response to a law enforcement agency request.</p>
          <p>(c) We may also use your personal information to protect the copyright, trademarks, legalrights, property or safety of Football Speedster, its application, website and customers or third parties.</p>
          <p>(d) Information that we collect may from time to time be stored, processed in or transferred between parties located in countries outside of Australia. These may include, but are not limited to United States.</p>
          <p>(e) If there is a change of control in our business or a sale or transfer of business assets, we reserve the right to transfer to the extent permissible at law our user databases, together with any personal information and non-personal information contained in those databases.This information may be disclosed to a potential purchaser under an agreement to maintain confidentiality. We would seek to only disclose information in good faith and where required by any of the above circumstances.</p>
          <p>(f) By providing us with personal information, you consent to the terms of this Privacy Policy and the types of disclosure covered by this Policy. Where we disclose your personal information to third parties, we will request that the third party follow this Policy regarding handling your personal information.</p>

          <p style={bold}>Security of your personal information</p>
          <p>(a) Football Speedster is committed to ensuring that the information you provide to us is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure information and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.</p>
          <p>(b) The transmission and exchange of information is carried out at your own risk. We cannotguarantee the security of any information that you transmit to us, or receive from us.Although we take measures to safeguard against unauthorised disclosures of information,we cannot assure you that personal information that we collect will not be disclosed in amanner that is inconsistent with this Privacy Policy.</p>

          <p style={bold}>Access to your personal information</p>
          <p>(a)You may request details of personal information that we hold about you in accordance withthe provisions of the Privacy Act 1988(Cth). A small administrative fee may be payable forthe provision of information. If you would like a copy of the information which we hold aboutyou or believe that any information we hold on you is inaccurate, out of date, incomplete, irrelevant or misleading, please email us at hello@footballspeedster.com.</p>
          <p>(b) We reserve the right to refuse to provide you with information that we hold about you, incertain circumstances set out in the Privacy Act.</p>

          <p style={bold}>Complaints about privacy</p>
          <p>(a) If you have any complaints about our privacy practices, please feel free to send in details of your complaints to 1 Main Rd, Ryde, New South Wales, 2112. We take complaints very seriously and will respond shortly after receiving written notice of your complaint.</p>

          <p style={bold}>Op out right</p>
          <p>(a) You can stop all collection of information by the Application easily by uninstalling the Application. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network. You can also request to opt-out via email, at hello@footballspeedster.com.</p>

          <p style={bold}>Changes to Privacy Policy</p>
          <p>(a) Please be aware that we may change this Privacy Policy in the future. We may modify this Policyat any time, in our sole discretion and all modifications will be effective immediately upon ourposting of the modifications on our website or notice board. Please check back from time to timeto review our Privacy Policy.</p>

          <p style={bold}>Software Application</p>
          <p>(a) When you come to our application we may collect certain information such as mobileunique device ID, the IP address of your mobile device, mobile operating system, the typeof mobile internet browsers you use, and information about the way you use theApplication. This information is used in an aggregated manner to analyse how people useour site, such that we can improve our service.</p>
          <p>(b) We may from time to time use cookies on our software application. Cookies are very small files which a website uses to identify you when you come back to the application and tostore details about your use of the application. Cookies are not malicious programs thataccess or damage your computer, tablet or smartphone. Most devices automatically accept cookies but you can choose to reject cookies by changing your devise settings. However, this may prevent you from taking full advantage of our application.</p>
          <p>(c) The software Application may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile devices unique device ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internetbrowsers you use, and information about the way you use the Application.</p>
          <p>(d) Our software application may from time to time have links to other applications or websitesnot owned or controlled by us. These links are meant for your convenience only. Links tothird party applications and websites do not constitute sponsorship or endorsement orapproval of these third parties. Please be aware that Football Speedster is not responsible for the privacy practises of other such applications or websites. We encourage our users to be aware, when they leave our application or website, to read the privacy statements of each and every application or website that collects personal identifiable information.</p>
          <p>(e) When you visit the mobile application, we may use GPS technology (or other similartechnology) to determine your current location in order to determine the city you arelocated within and display a location map with relevant advertisements. We will not shareyour current location with other users or partners.</p>
        </div>
      </div>
    </>
  );
}
